<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label" v-if="user.userTypeId != 4">My Patients</h3>
            <h3 class="card-label" v-if="user.userTypeId == 4">All Patients</h3>
          </div>
          <!-- <div class="card-toolbar">
            <div class="justify-content-center">
              <b-button @click="onExportAll" variant="info"
                >Export All Data</b-button
              >
            </div>
          </div> -->
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <b-table
              hover
              bordered
              v-if="patients"
              :items="patients"
              :fields="table_fields"
              @row-clicked="(item, index, event) => rowClickHandler(item)"
            ></b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "patients-dashboard",
  data() {
    return {
      show: true,
      patients: null,
      table_fields: [
        { key: "id", label: "Id", sortable: true },
        { key: 'clinical_reference', label: 'Clinical Reference', sortable: true },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Patients" }]);
    this.refresh();
  },
  methods: {
    refresh() {
      ApiService.get("/users/patients/").then(({ data }) => {   
        // get elements from data.data where element.group_id is equal to this.user.group_id
        this.patients = this.user.userTypeId == 4 ? data.data : data.data.filter(patient => patient.group_id == this.user.group_id);
      });
    },
    // onExportAll() {
    //   ApiService.get("/special-actions/export-all/", {
    //     responseType: "blob",
    //   }).then(({ data }) => {
    //     let blob = data;
    //     let reader = new FileReader();
    //     reader.readAsDataURL(blob);
    //     reader.onload = (e) => {
    //       let a = document.createElement("a");
    //       a.download = `export-all.zip`;
    //       a.href = e.target.result;
    //       document.body.appendChild(a);
    //       a.click();
    //       document.body.removeChild(a);
    //     };
    //   });
    // },
    rowClickHandler(item) {
      this.$router.push("/patient/" + item.id);
    },
  },
};
</script>

<style scoped>
.table-responsive {
  overflow-y: auto;
  max-height: 800px; /* Adjust the height as needed */
}

</style>